html,
body {
  height: 100%;
  position: relative;
}

.heroContainer {
  background-image: url("./assets/frontend.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  /* background: red; */
}

.portfolio {
  background-image: url("./assets/backend.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
